import { HeartAddToFav } from 'components/HeartAddToFav/HeartAddToFav';
import React from 'react';
import { SingleFlatType } from 'types/CommonTypes';
import {
    BottomTitle,
    BottomTitleWrapper,
    CardTitle,
    FlatCardWrapper,
    FlatStatus,
    InfoText,
    PriceText,
    RowWrapper,
    StyledButton,
    TitleWrapper,
} from './FlatCard.styled';

interface FlatCardProps {
    flatInfo: SingleFlatType;
    investmentSlug: string;
}

export const FlatCard = ({ flatInfo, investmentSlug }: FlatCardProps) => {
    const price = flatInfo?.flatPrice?.toLocaleString('pl');
    return flatInfo ? (
        <FlatCardWrapper>
            <TitleWrapper>
                <CardTitle>Mieszkanie {flatInfo?.flatNumber}</CardTitle>
                <HeartAddToFav
                    building={flatInfo?.investmentBuilding}
                    id={String(flatInfo?.flatNumber)}
                    size={20}
                />
            </TitleWrapper>
            <BottomTitleWrapper>
                <BottomTitle>
                    budynek {flatInfo?.investmentBuilding}
                </BottomTitle>
                •
                <FlatStatus flatIsFree={flatInfo?.flatStatus}>
                    {flatInfo?.flatStatus}
                </FlatStatus>
            </BottomTitleWrapper>
            <RowWrapper>
                <InfoText bold>Piętro</InfoText>
                <InfoText>{flatInfo?.buildingFloor}</InfoText>
            </RowWrapper>
            <RowWrapper>
                <InfoText bold>Ilość Pokoi</InfoText>
                <InfoText>{flatInfo?.flatRoomsQuantity}</InfoText>
            </RowWrapper>
            <RowWrapper>
                <InfoText bold>Metraż</InfoText>
                <InfoText noCapitalize>
                    {flatInfo?.flatArea} m<sup>2</sup>
                </InfoText>
            </RowWrapper>
            <RowWrapper>
                <InfoText bold>Zalety</InfoText>
                <InfoText>
                    {flatInfo?.flatAmenities}
                    {flatInfo?.flatFeatured && ', Wykończone'}
                </InfoText>
            </RowWrapper>
            <RowWrapper>
                <InfoText bold noCapitalize>
                    Cena za m<sup>2</sup>
                </InfoText>
                <InfoText noCapitalize>
                    {flatInfo?.priceMeter?.toLocaleString('pl')} zł
                </InfoText>
            </RowWrapper>
            <RowWrapper></RowWrapper>
            <RowWrapper>
                <InfoText bold>Cena</InfoText>
                <PriceText>{price} zł</PriceText>
            </RowWrapper>
            <StyledButton
                to={
                    '/' +
                    investmentSlug +
                    '/mieszkanie/' +
                    flatInfo?.investmentBuilding +
                    '/' +
                    flatInfo?.flatNumber
                }
            >
                Przejdź do mieszkania{' '}
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none">
                    <path
                        d="M14.9297 5.92969L20.9997 11.9997L14.9297 18.0697"
                        stroke="#466BA2"
                        strokeWidth="2"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M4.5 12L20.5 12"
                        stroke="#466BA2"
                        strokeWidth="2"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </StyledButton>
        </FlatCardWrapper>
    ) : (
        <></>
    );
};
