import styled, { css } from 'styled-components';
import { SecondaryButton } from 'styles/Typography.styled';

export const FlatCardWrapper = styled.div(
    ({ theme: { colors } }) =>
        css`
            background-color: ${colors.white};
            display: flex;
            flex-direction: column;
            padding: 36px;
        `,
);

export const TitleWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    width: 100%;
`;

export const BottomTitleWrapper = styled.div(
    ({ theme: { colors, fontSizes, fontWeight } }) =>
        css`
            display: flex;
            font-size: ${fontSizes.f12};
            font-weight: ${fontWeight.semiBold};
            line-height: 16px;
            margin-bottom: 16px;
            text-transform: uppercase;
        `,
);

export const CardTitle = styled.p(
    ({ theme: { colors, fontSizes, fontWeight } }) =>
        css`
            color: ${colors.neutral900};
            font-size: ${fontSizes.f28};
            font-weight: ${fontWeight.semiBold};
            line-height: 36px;
        `,
);

export const BottomTitle = styled.p(
    ({ theme: { colors } }) =>
        css`
            color: ${colors.neutral900};
            margin-right: 8px;
        `,
);

export const FlatStatus = styled.p<{ flatIsFree?: string }>(
    ({ theme: { colors }, flatIsFree }) =>
        css`
            color: ${flatIsFree === 'Wolne'
                ? colors.green
                : flatIsFree === 'Sprzedane'
                ? colors.error
                : colors.yellow};
            margin-left: 8px;
        `,
);

export const RowWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
`;

export const InfoText = styled.p<{ bold?: boolean; noCapitalize?: boolean }>(
    ({
        theme: { colors, fontWeight, fontSizes, letterSpacing },
        noCapitalize,
        bold,
    }) =>
        css`
            font-size: ${fontSizes.f14};
            font-weight: ${bold ? fontWeight.bold : fontWeight.medium};
            letter-spacing: ${letterSpacing.m};
            line-height: 18px;
            text-transform: ${noCapitalize ? 'none' : 'capitalize'};
        `,
);

export const PriceText = styled.p(
    ({ theme: { colors, fontWeight, fontSizes } }) =>
        css`
            color: ${colors.primaryBlue400};
            font-size: ${fontSizes.f24};
            font-weight: ${fontWeight.bold};
            letter-spacing: 0.005em;
            line-height: 29px;
        `,
);

export const StyledButton = styled(SecondaryButton)`
    margin: 0 auto;
    max-width: 280px;
    width: 100%;
    margin-top: 32px;

    svg {
        margin-left: 10px;
    }
`;
