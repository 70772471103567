import styled, { css } from 'styled-components';
import { LoadMoreButton } from 'styles/Table.styled';
import { SectionWrapper, H2, SecondaryButton } from 'styles/Typography.styled';
import { mediaQueries } from 'shared/breakpoints';

export const Container = styled(SectionWrapper)(
    ({ theme: { colors } }) => css`
        flex-direction: column;
        ${mediaQueries.md} {
            width: 100%;
            padding: 56px 14px 56px;
            background-color: ${colors.primaryBlue60};
        }
    `,
);

export const SectionTitle = styled(H2)(
    ({ theme: { colors } }) => css`
        margin: 80px 0 32px;

        ${mediaQueries.md} {
            margin: 0;
            background-color: ${colors.primaryBlue60};
            margin-bottom: 24px;
            text-align: center;
        }
    `,
);

export const Button = styled(SecondaryButton)`
    margin: 0 auto 32px;
    padding: 16px;
`;

export const FlatsListMobileWrapper = styled.div(
    ({ theme: { colors } }) => css`
        width: 100%;
        align-items: center;
        background-color: ${colors.primaryBlue60};
    `,
);

export const FlatCardWrapper = styled.div`
    margin-bottom: 32px;
`;

export const LoadMoreStyled = styled(LoadMoreButton)`
    margin: 0 36px;
    width: 100%;
    width: -moz-available;
    width: -webkit-fill-available;
`;
