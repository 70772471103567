import styled, { css } from 'styled-components';
import { sharedButtonStyles } from './Typography.styled';

export const TableWrapper = styled.div<{ noHover?: boolean }>(
    ({
        theme: { colors, fontSizes, fontWeight, letterSpacing },
        noHover,
    }) => css`
        width: 100%;
        z-index: 1;
        overflow-x: auto;

        table {
            width: 100%;
            border-spacing: 0;
            thead {
                th {
                    border-bottom: 2px solid ${colors.primaryBlue200};
                    font-size: ${fontSizes.f14};
                    font-weight: ${fontWeight.bold};
                    letter-spacing: ${letterSpacing.m};
                    line-height: 18px;
                    padding: 24px 12px;
                    text-align: left;
                    white-space: nowrap;

                    &:first-child {
                        padding-left: 32px;
                    }

                    &:last-child {
                        * {
                            display: none;
                        }
                    }
                    svg {
                        margin-left: 4px;
                    }
                }
            }

            tbody {
                th,
                td {
                    background-color: ${colors.white};
                    border-bottom: 2px solid ${colors.primaryBlue200};
                    border-right: 0;
                    font-size: ${fontSizes.f16};
                    font-weight: ${fontWeight.medium};
                    letter-spacing: ${letterSpacing.s};
                    line-height: 28px;
                    margin: 0;
                    padding: 24px 12px;
                    vertical-align: middle;
                    white-space: nowrap;

                    &:last-child {
                        pointer-events: none;
                    }
                    &:first-child {
                        padding-left: 32px;
                    }
                }

                tr {
                    cursor: ${noHover ? 'default' : 'pointer'};

                    &:last-child {
                        td {
                            border-bottom: none;
                        }
                    }

                    &:hover {
                        td {
                            background-color: ${noHover
                                ? 'transparent'
                                : colors.primaryBlue50};
                        }
                    }
                }
            }
        }
    `,
);

export const SortHeaderWrapper = styled.div`
    display: flex;
    span {
        align-items: center;
        display: flex;
    }
`;

export const LastColWrapper = styled.div`
    align-items: center;
    display: flex;

    * {
        pointer-events: all;
    }
`;

export const IsFree = styled.div<{ isFree?: string }>(
    ({ theme: { colors }, isFree }) =>
        css`
            color: ${isFree === 'Wolne'
                ? colors.green
                : isFree === 'Sprzedane'
                ? colors.error
                : colors.yellow};
        `,
);

export const Amenities = styled.div`
    align-items: center;
    display: flex;

    * {
        margin-right: 8px;
        :last-child {
            margin-right: 0px;
        }
    }
`;

export const HeartWrapper = styled.div`
    display: contents;
`;

export const FlatsTableWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
`;

export const FavoritesTableWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 104px;
    padding: 0 20px;
`;

export const LoadMoreButton = styled.button(
    ({ theme: { colors } }) =>
        css`
            ${sharedButtonStyles}
            background: ${colors.primaryBlue500};
            border: 2px solid transparent;
            color: ${colors.white};
            cursor: pointer;
            margin-top: 56px;
            padding: 16px 96px;

            &:hover {
                background: ${colors.primaryBlue700};
            }
        `,
);
