import { useState, useEffect } from 'react';

export const useContainerDimensions = (containerRef: any) => {
    const getDimensions = () => ({
        width: containerRef.current.offsetWidth,
        height: containerRef.current.offsetHeight,
    });

    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const handleResize = () => {
            setDimensions(getDimensions());
        };

        if (containerRef.current) {
            setDimensions(getDimensions());
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [containerRef]);

    return dimensions;
};
