import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ size: number }>(
    ({ theme: { colors }, size }) =>
        css`
            cursor: pointer;
            display: grid;
            margin: 0 12px;
            place-items: center;
            z-index: 1;
            position: relative;

            :hover {
                :before {
                    position: absolute;
                    display: block;
                    top: 50%;
                    transform: translateY(-50%);
                    background-color: ${colors.primaryBlue200};
                    border-radius: 50%;
                    content: '';
                    height: ${size * 1.75}px;
                    width: ${size * 1.75}px;
                    z-index: -1;
                }
            }
        `,
);
