import React from 'react';
interface ArrowIconProps {
    width: string;
    height: string;
    stroke: string;
}

export const ArrowDown = ({ width, height, stroke }: ArrowIconProps) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
            <path
                d="M18 10L13.0606 14.5932C12.4773 15.1356 11.5227 15.1356 10.9394 14.5932L6 10"
                stroke={stroke}
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const ArrowLeft = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                d="M9.56945 18.8201C9.37945 18.8201 9.18945 18.7501 9.03945 18.6001L2.96945 12.5301C2.67945 12.2401 2.67945 11.7601 2.96945 11.4701L9.03945 5.40012C9.32945 5.11012 9.80945 5.11012 10.0995 5.40012C10.3895 5.69012 10.3895 6.17012 10.0995 6.46012L4.55945 12.0001L10.0995 17.5401C10.3895 17.8301 10.3895 18.3101 10.0995 18.6001C9.95945 18.7501 9.75945 18.8201 9.56945 18.8201Z"
                fill="#466BA2"
            />
            <path
                d="M20.4999 12.75H3.66992C3.25992 12.75 2.91992 12.41 2.91992 12C2.91992 11.59 3.25992 11.25 3.66992 11.25H20.4999C20.9099 11.25 21.2499 11.59 21.2499 12C21.2499 12.41 20.9099 12.75 20.4999 12.75Z"
                fill="#466BA2"
            />
        </svg>
    );
};

export const SliderArrowLeft = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                d="M9.50195 5.93018L3.43195 12.0002L9.50195 18.0702"
                stroke="#20263B"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4 12L20 12"
                stroke="#20263B"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export const SliderArrowRight = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                d="M14.4297 5.93018L20.4997 12.0002L14.4297 18.0702"
                stroke="#20263B"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4 12L20 12"
                stroke="#20263B"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const DropdownArrow = () => {
    return (
        <svg
            width="14"
            height="7"
            viewBox="0 0 14 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13 1L8.06061 5.59317C7.47727 6.13561 6.52273 6.13561 5.93939 5.59317L1 1"
                stroke="#3C465B"
                strokeWidth="2"
                stroke-miterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const MotherWebsiteSliderArrow = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20 10C20 15.52 15.52 20 10 20C4.48 20 -6.78401e-07 15.52 -4.37114e-07 10C-1.95827e-07 4.48 4.48 -6.78401e-07 10 -4.37114e-07C15.52 -1.95827e-07 20 4.48 20 10ZM2 10C2 14.42 5.58 18 10 18C14.42 18 18 14.42 18 10C18 5.58 14.42 2 10 2C5.58 2 2 5.58 2 10ZM10 11L10 14L6 10L10 6L10 9L14 9L14 11L10 11Z"
                fill="#013A57"
            />
        </svg>
    );
};

export const MotherWebsiteSliderArrowHovered = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20 10C20 15.52 15.52 20 10 20C4.48 20 -6.78401e-07 15.52 -4.37114e-07 10C-1.95827e-07 4.48 4.48 -6.78401e-07 10 -4.37114e-07C15.52 -1.95827e-07 20 4.48 20 10ZM10 11L14 11L14 9L10 9L10 6L6 10L10 14L10 11Z"
                fill="#013A57"
            />
        </svg>
    );
};
