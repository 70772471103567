import Layout from 'layout/index';
import * as React from 'react';
import { Floor } from 'sections/Floor/Floor';
import { FloorMobile } from 'sections/FloorMobile/FloorMobile';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';
import { getDisabledPages } from 'utils/utils';

interface FloorPageProps {
    location: {
        pathname: string;
    };
    pageContext: {
        currentBuilding: string;
        currentFloor: string;
        headerData: any;
        floorData: any;
        slug: string;
        title: string;
    };
}

export default ({ location, pageContext }: FloorPageProps) => {
    const isMobile = useBreakpoint('md');

    const pageTitle = `${pageContext?.title} - Mieszkania - Piętro ${pageContext?.floorData?.floorTax}`;
    return (
        <Layout
            headerData={pageContext?.headerData}
            investmentSlug={pageContext?.slug}
            pageTitle={pageTitle}
            location={location}
            investmentDisabledPages={getDisabledPages(pageContext?.headerData)}
        >
            <>
                {!isMobile && (
                    <Floor
                        breadcrumbText="mieszkania"
                        pageContext={pageContext}
                    />
                )}
                {isMobile && (
                    <FloorMobile
                        data={pageContext}
                        investmentSlug={pageContext?.slug}
                    />
                )}
            </>
        </Layout>
    );
};
