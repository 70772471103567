import { useQuery } from '@apollo/client';
import { Breadcrumbs } from 'components/_layout/Breadcrumbs/Breadcrumbs';
import React, { useMemo, useRef, useState } from 'react';
import ImageMapper, { AreaEvent, CustomArea } from 'react-img-mapper';
import { ArrowLeft } from 'shared/svg/arrows';
import { AVAILABLE_FLATS_QUERY } from 'src/apollo/queries';
import { FloorFlatType } from 'types/CommonTypes';
import { useContainerDimensions } from 'utils/hooks/useContainerDimensions';
import { useImageRatio } from 'utils/hooks/useImageRatio';
import {
    FloorInnerWrapper,
    FloorWrapper,
    HeroLink,
    HeroTitle,
    HeroWrapper,
    ImageMapperWrapper,
    InfoBox,
    StyledBoxTitle,
    StyledFloorText,
    StyledIsFreeText,
    StyledReserveText,
    StyledSoldText,
    StyledTooltipFlat,
    StyledTooltipFlatIsFree,
    StyledTooltipText,
    TooltipFloor,
} from './Floor.styled';

interface FloorProps {
    breadcrumbText: string;
    pageContext: any;
}

interface StateTypes {
    apartmentNumber: string | null;
    price: string | null;
    status: string | null;
    tooltipIsVisible: boolean;
    yardage: string | null;
}

export const Floor = ({ breadcrumbText, pageContext }: FloorProps) => {
    const { data } = useQuery(AVAILABLE_FLATS_QUERY, {
        variables: {
            investmentBuilding: pageContext?.currentBuilding,
            investmentFloor: pageContext?.currentFloor,
            id: pageContext?.allData?.wpInvestment?.id,
        },
    });

    const searchMap = useMemo(() => {
        return pageContext?.floorData?.searchMap?.map(
            (item: FloorFlatType) => ({
                coords: item?.coordinates?.split(',')?.map((i) => Number(i)),
                href:
                    '/' +
                    pageContext?.slug +
                    '/mieszkanie/' +
                    item?.flat?.investmentFlat?.investmentBuilding?.name +
                    '/' +
                    item?.flat?.investmentFlat?.flatNumber,
                id:
                    item?.flat?.investmentFlat?.investmentBuilding?.name +
                    item?.flat?.investmentFlat?.flatNumber,
                shape: 'poly',
                preFillColor:
                    item?.flat?.investmentFlat?.flatStatus?.name === 'Wolne'
                        ? '#46A25A70'
                        : item?.flat?.investmentFlat?.flatStatus?.name ===
                          'Sprzedane'
                        ? '#DD432670'
                        : '#E0B85270',
                status: item?.flat?.investmentFlat?.flatStatus?.name,
                flatArea: item?.flat?.investmentFlat?.flatArea,
                flatNumber: item?.flat?.investmentFlat?.flatNumber,
                flatPrice: item?.flat?.investmentFlat?.flatPrice,
            }),
        );
    }, [pageContext?.floorData?.searchMap]);

    const [tooltip, setTooltip] = useState<StateTypes>({
        apartmentNumber: '',
        price: '',
        status: '',
        tooltipIsVisible: false,
        yardage: '',
    });
    const tooltipRef = useRef<HTMLSpanElement>(null);
    let cursorPositionX = 0;
    let cursorPositionY = 0;

    const imagesBoxRef = useRef<HTMLDivElement>(null);
    const { width } = useContainerDimensions(imagesBoxRef);
    const currentRatio = useImageRatio(
        pageContext?.floorData?.searchMapImage?.mediaDetails,
    );
    const imagesBoxWidth = width;
    const imagesBoxHeight = imagesBoxWidth * currentRatio;

    const moveOnArea = (area: CustomArea, index: number, event: AreaEvent) => {
        cursorPositionX = event.clientX;
        cursorPositionY = event.clientY;
        tooltipRef.current!.style.left = `${cursorPositionX}px`;
        tooltipRef.current!.style.top = `${cursorPositionY - 90}px`;
    };

    const enterArea = (area: any, index: number, event: AreaEvent) => {
        setTooltip({
            apartmentNumber: area.flatNumber,
            price: area.flatPrice,
            status: area.status,
            tooltipIsVisible: true,
            yardage: area.flatArea,
        });
    };

    const leaveArea = () => {
        setTooltip({
            apartmentNumber: null,
            price: null,
            status: null,
            tooltipIsVisible: false,
            yardage: null,
        });
    };
    return (
        <FloorWrapper>
            <Breadcrumbs text={breadcrumbText} />
            <HeroWrapper>
                <HeroTitle>Wybierz mieszkanie</HeroTitle>
                <HeroLink to={'/' + pageContext?.slug + '/mieszkania'}>
                    <ArrowLeft />
                    Wróć do wyboru piętra
                </HeroLink>
            </HeroWrapper>
            <FloorInnerWrapper>
                <ImageMapperWrapper ref={imagesBoxRef}>
                    <ImageMapper
                        imgWidth={
                            pageContext.floorData.searchMapImage.mediaDetails
                                .width
                        }
                        onMouseEnter={(
                            area: CustomArea,
                            index: number,
                            event: AreaEvent,
                        ) => {
                            enterArea(area, index, event);
                        }}
                        onMouseLeave={() => leaveArea()}
                        onMouseMove={(
                            area: CustomArea,
                            index: number,
                            event: AreaEvent,
                        ) => moveOnArea(area, index, event)}
                        src={pageContext.floorData.searchMapImage.sourceUrl}
                        width={imagesBoxWidth}
                        height={imagesBoxHeight}
                        fillColor={'rgb(0, 0, 0, 0.1)'}
                        map={{ name: 'building', areas: searchMap }}
                        key={'Floor'}
                    />
                    <TooltipFloor
                        ref={tooltipRef}
                        isVisible={tooltip.tooltipIsVisible}
                    >
                        <StyledTooltipFlat>
                            Mieszkanie {tooltip.apartmentNumber}
                        </StyledTooltipFlat>
                        <StyledTooltipFlatIsFree isFree={tooltip.status}>
                            {tooltip.status}
                        </StyledTooltipFlatIsFree>
                        <StyledTooltipText>
                            Metraż: {tooltip.yardage} m<sup>2</sup>
                        </StyledTooltipText>
                        <StyledTooltipText>
                            Cena: {tooltip?.price ? tooltip?.price + 'zł' : '-'}
                        </StyledTooltipText>
                    </TooltipFloor>
                </ImageMapperWrapper>
                <InfoBox>
                    <StyledBoxTitle>
                        Budynek{' '}
                        {pageContext.floorData.buildingTax.toUpperCase()}
                    </StyledBoxTitle>
                    <StyledFloorText>
                        Piętro: {pageContext.floorData.floorTax}
                    </StyledFloorText>
                    <StyledIsFreeText>
                        <span>Wolne</span>:{' '}
                        {data?.availableFlats?.pageInfo?.total}
                    </StyledIsFreeText>
                    <StyledReserveText>
                        <span>Zarezerwowane</span>:{' '}
                        {data?.bookedFlats?.pageInfo?.total}
                    </StyledReserveText>
                    <StyledSoldText>
                        <span>Sprzedane</span>:{' '}
                        {data?.soldFlats?.pageInfo?.total}
                    </StyledSoldText>
                </InfoBox>
            </FloorInnerWrapper>
        </FloorWrapper>
    );
};
