import React, { useEffect, useState } from 'react';
import { HeartIconSmall } from 'shared/svg/hearts';
import { Wrapper } from './HeartAddToFav.styled';
import { useTheme } from 'layout/ThemeContext';

interface HeartAddToFavProps {
    building: string;
    id: string;
    size: number;
}

export const HeartAddToFav = ({ building, id, size }: HeartAddToFavProps) => {
    const { liked, setLiked } = useTheme();
    const [isFavourite, setIsFavourite] = useState(false);
    const uniqueId = id + building;

    useEffect(() => {
        setIsFavourite(liked.includes(uniqueId));
    }, [liked]);

    const handleClick = () => {
        let newItems = [];
        if (liked.includes(uniqueId)) {
            newItems = liked.filter((item) => item != uniqueId);
        } else {
            newItems = [...liked, uniqueId];
        }
        const slug = localStorage.getItem('real-slug');
        localStorage.setItem('real-favorite-' + slug, JSON.stringify(newItems));
        setLiked(newItems);
    };

    return (
        <Wrapper onClick={handleClick} size={size}>
            <HeartIconSmall
                fill={isFavourite ? '#466BA2' : '#FFFFFF'}
                size={size}
            />
        </Wrapper>
    );
};
