import styled, { css } from 'styled-components';
import { mediaQueries } from 'shared/breakpoints';
import { H1, H4, HeroText, SectionText } from 'styles/Typography.styled';
import { Link } from 'gatsby';

export const FloorWrapper = styled.div(
    ({ theme: { colors } }) =>
        css`
            display: flex;
            flex-direction: column;
            padding: 80px 136px;
            margin-bottom: 78px;

            @media (max-width: 1100px) {
                padding: 32px 72px;
            }

            ${mediaQueries.md} {
                padding: 24px 16px;
            }
            ${mediaQueries.xs} {
                padding: 40px 16px;
            }
        `,
);

export const HeroTitle = styled(H1)``;

export const HeroLink = styled(Link)(
    ({ theme: { fontSizes, fontWeight, letterSpacing, colors } }) =>
        css`
            align-items: center;
            color: ${colors.primaryBlue500};
            display: flex;
            font-size: ${fontSizes.f16};
            font-weight: ${fontWeight.bold};
            letter-spacing: ${letterSpacing.s};
            line-height: 24px;
            white-space: nowrap;

            svg {
                margin-right: 8px;
            }
        `,
);

export const HeroWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 40px 0 56px 0;
`;

export const ImageMapperWrapper = styled.div`
    width: calc(100% - 364px);
`;

export const FloorInnerWrapper = styled.div`
    display: flex;
`;

export const TooltipFloor = styled.span<{ isVisible: boolean }>(
    ({ theme: { colors }, isVisible }) => css`
        background: ${colors.primaryBlue50};
        color: ${colors.neutral900};
        min-width: 200px;
        padding: 24px;
        pointer-events: none;
        position: fixed;
        transform: translate3d(-50%, -50%, 0);
        visibility: ${isVisible ? 'visible' : 'hidden'};
        z-index: 1000;

        &:after {
            background: ${colors.primaryBlue50};
            bottom: -2px;
            content: '';
            height: 14px;
            left: 50%;
            position: fixed;
            transform: rotate(45deg) translate(-50%, 50%);
            width: 19px;
        }
    `,
);

export const StyledTooltipFlat = styled(H4)`
    margin-bottom: 8px;
`;

export const sharedStylesTooltip = css(
    ({ theme: { fontWeight, fontSizes, letterSpacing } }) =>
        css`
            font-size: ${fontSizes.f12};
            font-weight: ${fontWeight.semiBold};
            letter-spacing: ${letterSpacing.l};
            line-height: 16px;
            margin-bottom: 8px;
            text-transform: uppercase;
        `,
);

export const StyledTooltipFlatIsFree = styled.p<{ isFree?: string | null }>(
    ({ theme: { colors }, isFree }) => css`
        ${sharedStylesTooltip}
        color: ${isFree === 'Wolne'
            ? colors.green
            : isFree === 'Sprzedane'
            ? colors.error
            : colors.yellow};
    `,
);
export const StyledTooltipFlatReserve = styled.p(
    ({ theme: { colors } }) => css`
        ${sharedStylesTooltip}
        color: ${colors.yellow};
    `,
);
export const StyledTooltipFlatSold = styled.p(
    ({ theme: { colors } }) => css`
        ${sharedStylesTooltip}
        color: ${colors.red};
    `,
);

export const StyledTooltipText = styled(SectionText)(
    ({ theme: { colors, fontWeight } }) => css`
        color: ${colors.neutral500};
        font-weight: ${fontWeight.medium};
    `,
);

export const StyledBoxTitle = styled(H4)`
    margin-bottom: 16px;
`;

export const InfoBox = styled.div(
    ({ theme: { colors } }) => css`
        background-color: ${colors.primaryBlue60};
        padding: 48px;
        height: fit-content;
        width: 294px;
        margin-left: 70px;
    `,
);

export const sharedStyles = css(
    ({ theme: { colors, fontWeight } }) =>
        css`
            color: ${colors.neutral900};
            font-weight: ${fontWeight.medium};
        `,
);

export const StyledFloorText = styled(SectionText)`
    ${sharedStyles}
    margin-bottom: 8px;
`;

export const StyledIsFreeText = styled(SectionText)(
    ({ theme: { colors } }) => css`
        ${sharedStyles}
        margin-bottom: 8px;

        span {
            color: ${colors.green};
        }
    `,
);

export const StyledReserveText = styled(SectionText)(
    ({ theme: { colors } }) => css`
        ${sharedStyles}
        margin-bottom: 8px;

        span {
            color: ${colors.yellow};
        }
    `,
);

export const StyledSoldText = styled(SectionText)(
    ({ theme: { colors } }) => css`
        ${sharedStyles}
        margin-bottom: 8px;

        span {
            color: ${colors.red};
        }
    `,
);
